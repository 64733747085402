import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const DomesticCleaningPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>Domestic Cleaning Service</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.domesticCleaning.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
          Frequent domestic cleaning is normally carried by a maid who comes in does a through clean of the home for the day. 
          All our staff are reliable, trained and have had backgrounds checked so you can be at ease knowing that a trustworthy individual is in your home. 
          Our frequent maid service covers follows a checklist to clean the entire house. We have a flat rate for out maid services and we do not add additional fees and charges. 
          With our frequent maid service the client has to provide all the cleaning detergents and cleaning equipment unless an alternative arrangement has been made. 
          Contact us now to find out more about our maid cleaning services.
        </div>
      </div>
    </Layout>
  )
}
)

export default DomesticCleaningPage

export const pageQuery = graphql`
  query {
    domesticCleaning: file(relativePath: {eq: "DomesticCleaning.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`